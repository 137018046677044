<template>
  <v-dialog v-model="internalValue" persistent :max-width="widthDialog">
    <v-card class="pb-3">
      <v-card-title class="text-h6 justify-center text-uppercase">
        <v-icon color="primary">mdi-alert-outline</v-icon>
      </v-card-title>
      <v-card-text class="text-center my-3">
        <v-row justify="center">
          {{ $t('token_expried') }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" class="mx-4 px-3 pb-3">
          <v-btn color="primary" @click="onAccept">
            {{ $t('accept') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import constants from '@/constants'
export default {
  name: 'DialogConfirmTokenExpried',
  props: {
    widthDialog: {
      type: Number,
      default: constants.widthDialog
    },
    value: Boolean
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
    }
  },
  methods: {
    onAccept() {
      window.location.reload()
    }
  }
}
</script>
