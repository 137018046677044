<template>
  <v-container class="container-content-base">
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout v-if="detail" column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-layout class="border-primary rounded pa-2 px-lg-6 py-sm-3">
          <v-row>
            <v-col cols="sm-6" class="col-12">
              <v-layout column>
                <h4 class="color-primary">{{ $t('place_holders.shop_order') }}</h4>
                <validation-provider>
                  <v-select
                    v-model="shopOrderSelected"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    class="rounded"
                    filled
                    disabled
                    hide-no-data
                    :items="listShopDetail"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column class="mt-2">
                <!-- Địa chỉ người gửi -->
                <v-layout justify-start>
                  <h4>
                    {{ $t("info-sender") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </h4>
                </v-layout>
                <v-layout class="border rounded pl-2 pr-8 my-2 py-2">
                  <v-row>
                    <v-col cols="sm-8" class="col-12">
                      <v-layout column class="mr-4">
                        <label class="text-subtitle-2">
                          {{ detail.senderName }}
                          <span
                            class="error--text text-min"
                          >
                            [{{ $t("default") }}]
                          </span>
                        </label>
                        <label
                          class="text-caption blur--text line-height-small"
                        >
                          {{ detail.senderPhone }}
                        </label>
                        <label
                          class="text-caption blur--text line-height-small"
                        >
                          {{ `${detail.senderAddress}` }}
                        </label>
                      </v-layout>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <v-btn color="primary" outlined disabled>
                        {{ $t("change") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-layout>

                <v-layout justify-start class="mt-4">
                  <h4>{{ $t("info-receiver") }}</h4>
                </v-layout>
                <v-layout class="d-flex">
                  <v-layout class="mr-2">
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("receiver_phone") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="phone"
                          :rules="phoneRules"
                        >
                          <v-text-field
                            v-model="detail.receiverPhone"
                            type="text"
                            pattern="\d*"
                            outlined
                            dense
                            :error-messages="errors"
                            :hide-details="receiverPhoneHideError"
                            :maxlength="maximumPhone"
                            :placeholder="$t('place_holders.phone')"
                            @keypress="isNumber($event)"
                            @input="inputPhone($event)"
                          />
                          <div
                            v-if="receiverPhoneError"
                            class="v-messages theme--light error--text mt-1 ml-3"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ receiverPhoneError }}
                              </div>
                            </div>
                          </div>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                  <v-layout>
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("receiver_full_name") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="require"
                          vid="name"
                          :rules="requireRules"
                        >
                          <v-text-field
                            v-model="detail.receiverName"
                            outlined
                            dense
                            :error-messages="errors"
                            :maxlength="maximumSmallText"
                            :placeholder="$t('place_holders.name')"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-layout>
                <!-- Địa chỉ nhận hàng -->
                <v-layout column>
                  <label>
                    {{ $t("province") }}/{{ $t("district") }}/{{
                      $t("commune")
                    }}
                    <span class="error--text">*</span>
                  </label>
                  <div class="d-flex gap-10">
                    <validation-provider>
                      <v-autocomplete
                        v-model="detail.receiverProvinceCode"
                        outlined
                        dense
                        filled
                        disabled
                        class="rounded"
                        item-text="name"
                        item-value="code"
                        hide-no-data
                        :items="lstProvince"
                        :placeholder="$t('place_holders.province')"
                        @change="onChangeProvinceOrder"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      ref="districtOrder"
                      name="selectRequire"
                      vid="districtOrder"
                      :rules="requireRules"
                    >
                      <v-autocomplete
                        ref="inputDistrictOrder"
                        v-model="receiverDistrict"
                        outlined
                        dense
                        class="rounded"
                        item-text="name"
                        item-value="code"
                        hide-no-data
                        :items="lstDistrictOrder"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.district')"
                        :disabled="districtDisabledOrder"
                        @change="onChangeDistrictOrder"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      ref="communeOrder"
                      name="selectRequire"
                      vid="communeOrder"
                      :rules="requireRules"
                    >
                      <v-autocomplete
                        ref="inputCommuneOrder"
                        v-model="receiverCommune"
                        outlined
                        dense
                        class="rounded"
                        item-text="name"
                        item-value="id"
                        hide-no-data
                        :placeholder="$t('place_holders.commune')"
                        :items="lstCommuneOrder"
                        :error-messages="errors"
                        :disabled="communeDisabledOrder"
                      />
                    </validation-provider>
                  </div>
                </v-layout>
                <!-- Địa chỉ cụ thể -->
                <v-layout>
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("custom_address") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="textRequired"
                        vid="customAddress"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="address"
                          outlined
                          dense
                          :maxlength="maximumSmallText"
                          :error-messages="errors"
                          :placeholder="$t('place_holders.type_custom_address')"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-layout>
                <!--Thong tin van chuyen-->
                <v-layout justify-start>
                  <h4>{{ $t("info-shipping") }}</h4>
                </v-layout>
                <v-layout>
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("group-shipping") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <validation-provider
                        name="require"
                        vid="partnerName"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="detail.partnerName"
                          outlined
                          dense
                          filled
                          disabled
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-layout>
                <v-layout>
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("shipping_service") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <validation-provider
                        name="require"
                        vid="serviceName"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="detail.serviceName"
                          outlined
                          dense
                          filled
                          disabled
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-layout>
                <v-layout justify-start>
                  <h4>{{ $t("shipping_method") }}</h4>
                </v-layout>
                <v-layout justify-start>
                  <v-radio-group
                    v-model="detail.transportType"
                    mandatory
                    row
                    class="mt-0"
                    disabled
                  >
                    <v-radio
                      :label="$t('shipper_pickup')"
                      :value="transportTypeConst.ship"
                    />
                    <v-radio
                      :label="$t('customer_bring_order')"
                      :value="transportTypeConst.individual"
                    />
                  </v-radio-group>
                </v-layout>

                <v-layout>
                  <v-row v-if="isShowPickUpDateShipping">
                    <v-col cols="sm-6" class="col-12 pb-0">
                      <div>
                        {{ $t("pickup_date") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <v-text-field
                        v-model="computedPickupDate"
                        append-icon="mdi-calendar"
                        outlined
                        filled
                        disabled
                        dense
                      />
                    </v-col>
                    <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
                      <div>
                        {{ $t("pickup_time") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <v-text-field
                        v-if="detail.partnerId === constants.partnerGroup.ninjaVan"
                        v-model="computedPickupTime"
                        append-icon="mdi-chevron-down"
                        outlined
                        filled
                        disabled
                        dense
                      />
                      <v-text-field
                        v-else
                        v-model="computedPickWorkShift"
                        outlined
                        filled
                        disabled
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-layout>
              </v-layout>
            </v-col>
            <!-- Thông tin đơn hàng -->
            <v-col>
              <v-layout column>
                <v-layout justify-start>
                  <h4>{{ $t("order_info") }}</h4>
                </v-layout>
                <!-- Mô tả sản phẩm -->
                <v-layout class="mt-4">
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("product_name") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="textRequired"
                        vid="description"
                        :rules="requireRules"
                      >
                        <v-textarea
                          v-model="detail.description"
                          :maxlength="maximumSmallText"
                          :error-messages="errors"
                          rows="3"
                          outlined
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-layout>
                <!-- Quy đổi kích thước và khối lượng -->
                <div>
                  {{ $t("calculate_size") }}
                  <span class="red--text">
                    {{ $t("icon_required") }}
                  </span>
                  <v-icon>mdi-compare-horizontal</v-icon>
                  {{ $t("tranfer_weight") }}: {{ detail.estimateWeight
                  }}{{ $t("kg") }}
                </div>
                <v-row class="justify-center">
                  <!-- Dài -->
                  <validation-provider class="container col-sm-4 col-12">
                    <v-text-field
                      v-model="detail.length"
                      outlined
                      dense
                      filled
                      disabled
                      suffix="cm"
                      class="pr-sm-2"
                    />
                  </validation-provider>
                  <!-- Rộng -->
                  <validation-provider
                    class="container col-sm-4 col-12 px-sm-0 py-0 py-sm-3"
                  >
                    <v-text-field
                      v-model="detail.width"
                      outlined
                      dense
                      filled
                      disabled
                      suffix="cm"
                      prepend-icon="mdi-plus"
                    />
                  </validation-provider>
                  <!-- Cao -->
                  <validation-provider class="container col-sm-4 col-12">
                    <v-text-field
                      v-model="detail.height"
                      outlined
                      dense
                      filled
                      disabled
                      suffix="cm"
                      prepend-icon="mdi-plus"
                      class="ml-sm-2"
                    />
                  </validation-provider>
                </v-row>
                <!-- Khối lượng đơn hàng -->
                <v-layout>
                  <v-row>
                    <v-col cols="sm-6" class="col-12 pb-0">
                      <div>
                        {{ $t("order_weight")
                        }}<span class="red--text">{{
                          $t("icon_required")
                        }}</span>
                      </div>
                      <validation-provider>
                        <v-text-field
                          v-model="weightValue"
                          dense
                          filled
                          disabled
                          suffix="kg"
                          outlined
                        />
                      </validation-provider>
                    </v-col>
                    <!-- COD thu hộ -->
                    <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
                      <div>{{ $t("cod") }}
                        <span class="red--text">{{
                          $t("icon_required")
                        }}</span>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="require"
                        vid="cod"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="moneyCod"
                          dense
                          outlined
                          :error-messages="errors"
                          :maxlength="maximumMoneyCod"
                          :placeholder="$t('place_holders.type_cod')"
                          :hide-details="codValueHideError"
                          @keypress="isNumberFloat($event)"
                          @input="onInputCOD"
                          @paste.prevent="onPasteCOD($event)"
                        />
                        <div
                          v-if="codValueError"
                          class="v-messages theme--light error--text mt-1 ml-3"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ codValueError }}
                            </div>
                          </div>
                        </div>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-layout>
                <v-layout>
                  <v-row>
                    <!-- Giá trị đơn hàng -->
                    <v-col
                      cols="sm-6"
                      class="col-12 pt-0 pt-sm-3"
                    >
                      <div>
                        {{ $t("order_value") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <validation-provider>
                        <v-text-field
                          v-model="detail.orderValue"
                          outlined
                          dense
                          filled
                          disabled
                        />
                      </validation-provider>
                    </v-col>
                    <!-- Đối tượng trả phí dịch vụ -->
                    <v-col>
                      <div>
                        {{ $t("payer") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectRequire"
                        vid="serviceFeePayers"
                        :rules="requireRules"
                      >
                        <v-radio-group
                          v-model="detail.paymentBy"
                          row
                          class="mt-0"
                          :error-messages="errors"
                          @change="onChangeServiceFeePayer"
                        >
                          <v-radio
                            :label="$t('sender')"
                            :value="serviceFeePayersConst.sender"
                          />
                          <v-radio
                            :label="$t('receiver')"
                            :value="serviceFeePayersConst.receiver"
                          />
                        </v-radio-group>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-layout>
                <!--Ma van don theo yeu cau-->
                <v-layout v-if="detail.partnerId === constants.partnerGroup.ninjaVan">
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("way_bill_required") }}
                      </div>
                      <v-text-field
                        v-model="detail.deliveryCode"
                        outlined
                        dense
                        filled
                        disabled
                      >
                        {{ waybillPrefix }}
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-layout>
                <!-- Ghi chú giao hàng -->
                <v-layout v-if="detail.partnerId === constants.partnerGroup.ninjaVan">
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("note_order") }}
                        <span class="red--text">
                          {{ $t("icon_required") }}
                        </span>
                      </div>
                      <v-select
                        v-model="detail.noteCode"
                        outlined
                        dense
                        class="rounded"
                        filled
                        disabled
                        item-text="name"
                        item-value="code"
                        hide-no-data
                        :items="lstDeliveryNote"
                      />
                    </v-col>
                  </v-row>
                </v-layout>
                <!-- Dịch vụ vận chuyển -->
                <div class="d-flex">
                  <v-layout column>
                    <div>
                      {{ $t("establish-service") }}
                    </div>
                    <div v-for="(item, index) in lstFeeTag" :key="index">
                      <v-checkbox
                        v-model="item.checkFee"
                        multiple
                        hide-details
                        disabled
                        :label="item.name"
                        :value="item.id"
                        @change="checkCode(item.id, item.unit, item.value)"
                      >
                        <template v-slot:label>
                          <div class="d-flex justify-space-between">
                            <div class="max-line-1">
                              {{ item.name }}
                            </div>
                            <v-tooltip
                              top
                              max-width="250"
                              color="white"
                              class="box-shadow"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <span style="color: #0F90E8; text-decoration: underline; cursor: pointer">{{ item.description }}</span>
                                </div>
                              </template>
                              <label v-html="item.note" />
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-layout>
                </div>
                <!-- Phí phải trả -->
                <v-layout column>
                  <v-layout justify-space-between>
                    <h6 class="text-subtitle-1">
                      {{ $t("fee_delivery") }} ({{ $t("vnd_unit") }})
                    </h6>
                    <h6 class="text-subtitle-1 font-weight-medium">
                      {{ formatDecimal(detail.shipValue) }}
                    </h6>
                  </v-layout>
                  <v-layout
                    justify-space-between
                  >
                    <h6 class="text-subtitle-1">
                      {{ $t("fee_total") }} ({{ $t("vnd_unit") }})
                    </h6>
                    <h6 class="text-subtitle-1 font-weight-medium">
                      {{ formatDecimal(detail.serviceFeesValue) }}
                    </h6>
                  </v-layout>
                  <v-layout justify-space-between>
                    <h6 class="text-subtitle-1 font-weight-medium">
                      {{ $t("total_fee-service") }} ({{ $t("vnd_unit") }})
                    </h6>
                    <h6 class="text-subtitle-1 font-weight-medium">
                      {{
                        formatDecimal(detail.shipValue + detail.serviceFeesValue)
                      }}
                    </h6>
                  </v-layout>
                </v-layout>
                <!-- Tổng thu -->
                <v-layout class="border rounded pl-2 pr-2 pr-sm-8 my-2 py-2">
                  <v-row>
                    <v-col cols="sm-8" class="col-12">
                      <span>
                        <v-icon color="primary">mdi-cards</v-icon>
                        {{ $t("total_revenue") }} ({{ $t("vnd_unit") }})
                      </span>
                    </v-col>
                    <v-col class="text-right">
                      <span class="text-h5">{{
                        formatDecimal(detail.totalValue)
                      }}</span>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-layout>
            </v-col>
          </v-row>
        </v-layout>
        <!-- Button submit -->
        <v-layout class="mt-4 d-flex justify-end">
          <v-btn outlined @click="goBack">{{ $t("cancel") }}</v-btn>
          <v-btn
            :disabled="invalid || codValueHideError || disableBtn || checkStatus"
            color="primary"
            class="ml-3"
            @click="onShowDialogConfirm"
          >
            {{ $t("save_update") }}
          </v-btn>
        </v-layout>
      </validation-observer>
    </v-layout>

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm update -->
    <dialog-confirm
      v-model="showDialogConfirmUpdate"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelUpdate"
      :on-accept="onAcceptUpdate"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import constants from "@/constants";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";

// services
import { StorageService } from "@/services/storageService";
import { OrderService } from "@/services/orderService";
// import { UserService } from "@/services/userService";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { CommonService } from "@/services/commonService";
import routePaths from "@/router/routePaths";
import { UserService } from "@/services/userService";

export default {
  components: {
    Breadscrumbs,
    DialogNotification,
    DialogConfirmTokenExpried,
    DialogConfirm,
  },
  data() {
    return {
      constants,
      transportTypeConst: constants.transportType,
      serviceFeePayersConst: constants.serviceFeePayers,
      maximumPriceFm: constants.maximumPriceFm,
      maximumMoneyCod: constants.maximumMoneyCod,
      orderInsuranceConst: constants.orderInsurance,
      maximumSmallText: constants.maximumSmallText,
      maximumLargeText: constants.maximumLargeText,
      requireRules: {
        required: true,
      },
      minimumWaybill: constants.minimumWaybill,
      maximumWaybill: constants.maximumWaybill,
      waybillRules: {
        min: constants.minimumWaybill,
        max: constants.maximumWaybill,
        regex: constants.regExp.waybill,
      },
      waybillPrefix: constants.waybillPrefix,
      maximumSizeLwH: constants.maximumSizeLwHFm,
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        regex: constants.regExp.phone,
      },
      moneyCodRules: {
        required: true,
        regex: constants.regExp.money_cod,
      },
      codValueHideError: false,
      codValueError: "",

      id: 0,
      detail: null,

      lstProvince: [],
      lstDistrictOrder: [],
      lstCommuneOrder: [],
      lstDeliveryNote: [],

      logisticServiceList: [{ name: "Tiêu chuẩn", value: 1 }],
      logisticService: null,

      address: "",
      moneyCod: "",
      weightValue: null,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      showConfirmTokenExpried: false,
      textCancel: this.$t("cancel"),
      textAccept: this.$t("save"),
      showDialogConfirmUpdate: false,
      districtDisabledOrder: false,
      communeDisabledOrder: false,
      receiverDistrict: null,
      receiverCommune: null,
      shopOrderSelected: "",
      listShopDetail: [],
      partnerSelect: null,
      lstFeeTag: [],
      checkFee: [],
      ArrayTag: [],
      listFeeTagMap: [],
      menuPhone: false,
      listReceiver: [],
      receiverPhoneError: "",
      receiverPhoneHideError: false,
      breadcrumbs: [
        {
          text: 'order_list',
          disabled: false,
          isActive: false,
          href: routePaths.ORDERS
        },
        {
          text: "order_detail",
          disabled: false,
          isActive: false,
          href: ''
        },
        {
          text: 'order_update',
          disabled: true,
          isActive: true,
        },
      ],
      disableBtn: false,
      statusDetail: null
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    isShowPickUpDateShipping() {
      return this.detail.transportType === constants.transportType.ship;
    },
    computedPickupDate() {
      if (this.detail.partnerId === constants.partnerGroup.ninjaVan) {
        return DateTimeUtils.formatDateTime(
          this.detail.pickupTimeFrom,
          constants.formatDate
        )
      }
      return DateTimeUtils.formatDateTime(
        this.detail.pickDate,
        constants.formatDate
      );
    },
    computedPickupTime() {
      const timeFrom = DateTimeUtils.formatDateTime(
        this.detail.pickupTimeFrom,
        constants.formatTimeHm
      );
      const timeTo = DateTimeUtils.formatDateTime(
        this.detail.pickupTimeTo,
        constants.formatTimeHm
      );
      return `${timeFrom} - ${timeTo}`;
    },
    computedPickWorkShift() {
      if (this.detail.pickWorkShift === constants.pickWorkShip.morning) {
        return this.$t("morning")
      } else if (this.detail.pickWorkShift === constants.pickWorkShip.afternoon) {
        return this.$t("afternoon")
      } else {
        return this.$t("night")
      }
    },
    computedWeight() {
      return this.hasNewWeight ? this.detail.newWeight : this.detail.weight;
    },
    hasNewWeight() {
      return this.detail.newWeight > 0;
    },
    isShowTextBoxTypeOrderValue() {
      return this.detail.insuranceValue && this.detail.insuranceValue > 0;
    },
    checkStatus() {
      return this.detail.status === constants.orderStatus.cancelled || this.detail.status === constants.orderStatus.successfulDelivery || this.detail.status === constants.orderStatus.refundedToSender
    }
  },
  created() {
    this.id = parseInt(this.$route.query.id);
    this.logisticService = this.logisticServiceList[0];
    this.breadcrumbs[1].href = `${routePaths.ORDER_DETAIL}?id=${this.id}`
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    async initData() {
      await this.getProvinces();
      await this.getListDeliveryNote();
      await this.getDetail();
      this.getShops();
      this.getEstablishServiceByPartner()
      if (this.statusDetail === constants.orderStatus.cancelled || this.statusDetail === constants.orderStatus.successfulDelivery || this.statusDetail === constants.orderStatus.refundedToSender) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("no-mission-msg"),
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, constants.timeOutRole);
      }
    },
    async getShops() {
      const pars = {
        poId: null
      }
      const { status, data } = await UserService.getShops(pars);
      if (status === constants.statusCode.ok && data) {
        this.listShopDetail = data;
        this.listShopDetail.forEach((it) => {
          if (it.id === this.detail.custId) {
            this.shopOrderSelected = it.id
          }
        })
      }
    },
    onShowDialogConfirm() {
      this.message = this.$t("msg_save_update_order");
      this.showDialogConfirmUpdate = true;
    },
    onCancelUpdate() {
      this.showDialogConfirmUpdate = false;
    },
    async onAcceptUpdate() {
      this.onCancelUpdate();
      this.showLoading = true;
      this.disableBtn = true
      const pars = this.bindRequestUpdatePars();
      const { status, data } = await OrderService.update(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("msg_success_update_order"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          this.$router.push({
            path: routePaths.ORDER_DETAIL,
            query: { id: this.id },
          });
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
        this.disableBtn = false
      }
    },
    bindRequestUpdatePars() {
      const pars = {
        ordersId: this.detail.id,
        receiverAddress: this.getTextAddressReceiver(),
        paymentBy: this.detail.paymentBy,
        codValue: StringUtils.numStrToFloat(this.moneyCod),
        receiverName: this.detail.receiverName,
        description: this.detail.description,
        receiverPhone: this.detail.receiverPhone,
        receiverDistrictCode: this.receiverDistrict,
        receiverCommuneCode: this.receiverCommune,
      };
      if (this.receiverDistrict) {
        pars["receiverDistrictCode"] = this.receiverDistrict;
      }
      if (this.receiverCommune) {
        pars["receiverCommuneCode"] = this.receiverCommune;
      }
      return pars;
    },
    getTextAddressReceiver() {
      return `${this.address.trim()}`;
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    async getDistrictByProvinceCodeOrder(code) {
      const pars = {
        provinceCode: code,
      };
      const { status, data } = await UserService.findDistrictByProvinceCode(
        pars
      );
      if (data && data.length > 0) {
        this.lstDistrictOrder = data;
        this.lstDistrictOrder.forEach((item) => {
          if (item.name === this.detail.receiverDistrictName) {
            this.receiverDistrict = item.code;
          }
        });
        await this.getCommuneByDistrictCodeOrder(this.receiverDistrict);
        this.districtDisabledOrder = false;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async getCommuneByDistrictCodeOrder(code) {
      const pars = {
        districtCode: code,
      };
      const { status, data } = await UserService.findCommuneByDistrictCode(
        pars
      );
      if (data && data.length > 0) {
        this.lstCommuneOrder = data;
        this.lstCommuneOrder.forEach((item) => {
          if (item.name === this.detail.receiverCommuneName) {
            this.receiverCommune = item.id;
          }
        });
        this.communeDisabledOrder = false;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async onChangeProvinceOrder(val) {
      if (parseInt(this.detail.receiverProvinceCode) !== val) {
        this.detail.receiverProvinceCode = val;
        this.clearDistrict();
        this.clearCommune();
        await this.getDistrictByProvinceCodeOrder(val);
      }
    },
    async onChangeDistrictOrder(val) {
      this.clearCommune();
      await this.getCommuneByDistrictCodeOrder(val);
      this.$refs.inputDistrictOrder.blur();
      this.$refs.inputCommuneOrder.focus();
    },
    clearDistrict() {
      this.detail.receiverDistrictName = null;
      this.receiverDistrict = null;
      this.lstDistrictOrder = [];
      this.districtDisabledOrder = true;
    },
    clearCommune() {
      this.detail.receiverCommuneName = null;
      this.receiverCommune = null;
      this.lstCommuneOrder = [];
      this.communeDisabledOrder = true;
    },
    async getListDeliveryNote() {
      this.showLoading = false;
      const { status, data } = await CommonService.getListDeliveryNote();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.lstDeliveryNote = data;
      }
    },
    inputPhone(val) {
      val = val + "";
      if (val === this.detail.senderPhone) {
        const msg = this.$t("duplicate_phone_sender_receiver");
        this.receiverPhoneError = msg
        this.receiverPhoneHideError = true;
      } else {
        this.receiverPhoneError = ''
        this.receiverPhoneHideError = false;
      }
    },
    async getDetail() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await OrderService.getDetail(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.detail = data;
        this.statusDetail = this.detail.status
        this.moneyCod = this.detail.codValue
        this.weightValue = this.detail.newWeight ? this.formatDecimal(this.detail.newWeight) : this.formatDecimal(this.detail.weight)
        this.moneyCod = StringUtils.formatNumber(StringUtils.numStrToFloat(this.moneyCod).toFixed());
        this.detail.orderValue = StringUtils.formatNumber(StringUtils.numStrToFloat(this.detail.orderValue).toFixed());
        this.ArrayTag = this.detail.tags.split(",")
        if (this.detail.receiverProvinceCode) {
          this.getDistrictByProvinceCodeOrder(this.detail.receiverProvinceCode);
        }
        this.prepareDataForm(data);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    async getEstablishServiceByPartner(par) {
      try {
        const response = await OrderService.getEstablishServiceByPartner(this.detail.partnerId)
        this.lstFeeTag = response.data
        this.listFeeTagMap = this.lstFeeTag.map((item) => item.id)
        this.ArrayTag.forEach((itTag) => {
          if (this.listFeeTagMap.includes(parseInt(itTag))) {
            this.lstFeeTag.forEach((it) => {
              if (this.listFeeTagMap.includes(parseInt(itTag)) && it.id === parseInt(itTag)) {
                it.checkFee = [parseInt(itTag)]
                it.isDisableInsuranceCheckBox = true
              } else {
                it.isDisableInsuranceCheckBox = true
              }
            })
          }
        })
      } catch (error) {
        console.log(error);
      }
    },
    bindRequestPars() {
      const pars = {
        ordersId: this.id,
      };
      return pars;
    },
    async prepareDataForm(data) {
      // receiver address
      this.address = data.receiverAddress;

      // note
      const noteSelected = this.lstDeliveryNote.find(
        (x) => x.name === data.note
      );
      if (noteSelected) {
        this.detail["noteCode"] = noteSelected.code;
      }
      this.detail.estimateWeight = StringUtils.formatDecimal(
        data.estimateWeight
      );

      if (data.insuranceValue && data.insuranceValue > 0) {
        this.detail["insurance"] = this.orderInsuranceConst.yes;
      } else {
        this.detail["insurance"] = this.orderInsuranceConst.no;
      }

      if (data.codValue) {
        this.moneyCod = StringUtils.formatNumber(data.codValue);
      }

      if (data.orderValue && data.orderValue > 0) {
        this.detail.orderValue = StringUtils.formatNumber(data.orderValue);
      }
    },
    // getAddressByReceiverAddress(val) {
    //   const arrVal = val.split(",");
    //   const results = [];
    //   for (let index = 0; index < arrVal.length - 1; index++) {
    //     results.push(arrVal[index]);
    //   }
    //   return results.join(",");
    // },
    onInputCOD(val) {
      this.moneyCod = StringUtils.formatNumber(val);
      this.calculateTotalRevenue();
      this.checkValidCodValue();
    },
    isNumberValue(event) {
      if (event.key === ".") {
        return event.preventDefault();
      } else {
        return true;
      }
    },
    checkValidCodValue() {
      if (
        parseFloat(StringUtils.replaceComma(this.moneyCod)) >
        constants.maximumCodValue
      ) {
        const msg = this.$t("maximum_cod_message");
        this.codValueError = msg;
        this.codValueHideError = true;
      } else {
        this.codValueError = "";
        this.codValueHideError = false;
      }
    },
    onPasteCOD(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      );
      if (val !== null) {
        this.moneyCod = StringUtils.formatNumber(val);
        this.calculateTotalRevenue();
        this.checkValidCodValue();
      }
    },
    onChangeServiceFeePayer(val) {
      this.calculateTotalRevenue();
    },
    calculateTotalRevenue() {
      if (this.detail.paymentBy === this.serviceFeePayersConst.receiver) {
        const fees =
          StringUtils.numStrToFloat(this.detail.shipValue) +
          StringUtils.numStrToFloat(this.detail.serviceFeesValue) +
          StringUtils.numStrToFloat(this.moneyCod);
        this.detail.totalValue = StringUtils.formatNumber(fees.toFixed());
      } else {
        const fees = StringUtils.numStrToFloat(this.moneyCod);
        this.detail.totalValue = StringUtils.formatNumber(fees.toFixed());
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    formatDecimal(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : '';
    },
    formatNumber(val) {
      return val ? StringUtils.formatNumber(val) : '';
    },
    isNumber(event) {
      return FormUtils.isNumber(event);
    },
    isNumberFloat(event) {
      if (event.key === ".") {
        return event.preventDefault();
      } else {
        return FormUtils.isNumber(event);
      }
    },
    isDecimal(evt) {
      return FormUtils.isDecimal(evt);
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt);
      if (val !== null) {
        this.phone = val;
      }
    },
    goBack() {
      this.$router.push({
        path: routePaths.ORDER_DETAIL,
        query: { id: this.id },
      });
    },
  },
};
</script>

<style src="@/styles/updateOrder.scss" lang="scss" />
