<template>
  <v-dialog v-model="internalValue" persistent :max-width="widthDialog">
    <v-card class="pb-3">
      <v-card-title class="text-h6 justify-center text-uppercase">
        <v-icon color="primary">{{ iconSrc }}</v-icon>
      </v-card-title>
      <v-card-text class="text-center my-3">
        <v-row justify="center" class="px-4" style="font-size: 14px; color: #0A1C02; line-height: 18px; font-weight: 400">
          {{ message }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-between" class="mx-4 px-6 pb-3">
          <v-btn @click="onCancel">
            {{ textCancel }}
          </v-btn>
          <v-btn color="#0F90E8" @click="onAccept">
            <span style="color: #FFFFFF">
              {{ textAccept }}
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import constants from '@/constants'
export default {
  name: 'DialogConfirm',
  props: {
    widthDialog: {
      type: Number,
      default: constants.widthDialog
    },
    value: Boolean,
    iconSrc: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    textCancel: {
      type: String,
      default: null
    },
    textAccept: {
      type: String,
      default: null
    },
    onAccept: {
      type: Function,
      default: undefined
    },
    onCancel: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
    }
  }
}
</script>
